<template>
  <div class="guard-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <div>
      <app-overview :list="detialsList"></app-overview>
    </div>
    <div style="display: flex;justify-content: space-around;width: 1204px">
      <div style="display: inline-block">
        <div style="margin-bottom: 20px">证件内头像</div>
        <img :src="img1" style="width: 450px;height:280px" />
      </div>
      <div style="display: inline-block;width: 450px">
        <div style="margin-bottom: 20px">现场图片</div>
        <img :src="img2" style="width: 450px;height:280px" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // let _this=this,
    return {
      // isLoading: true,
      title: "查看详情",
      img1: "",
      img2: "",
      detialsList: [
        {
          label: "人证机SN",
          key: "rzjNo",
          value: ""
        },
        {
          label: "人证机安装位置",
          key: "area",
          value: ""
        },
        {
          label: "城市",
          key: "cityName",
          value: ""
        },
        {
          label: "房源名称",
          key: "apartmentName",
          value: ""
        },
        {
          label: "开门方式",
          key: "unlockMethod",
          value: ""
        },
        {
          label: "开门人类型",
          key: "userType",
          value: ""
        },
        {
          label: "开门人姓名",
          key: "name",
          value: ""
        },
        {
          label: "开门人性别",
          key: "gender",
          value: ""
        },
        {
          label: "开门时间",
          key: "unlockDate",
          value: ""
        },
        {
          label: "开门结果",
          key: "unlockState",
          value: ""
        }
      ]
    };
  },
  activated() {
    console.log("guard-detail created!!");
    // if (this.$route.query.type == "蓝牙网关") {
    //   this.type = 1;
    //   this.title = "设备详情（蓝牙网关）";
    // } else {
    //   this.type = 0;
    //   this.title = "设备详情（中央控制器）";
    // }
    this.guardDetail();
  },

  methods: {
    guardDetail() {
      var dto = {
        id: this.$route.query.id
      };
      this.post("/device-service/rzjmanagment/getRzjEntryRecordById", dto, {
        isUseResponse: true
      }).then(res => {
        var data = res.data.data;
        data.unlockMethod = [" ", " ", "人脸识别", "身份证识别", "远程开门"][
          data.unlockMethod - 1
        ];
        data.unlockState = ["开门成功", "开门失败"][data.unlockState - 1];
        data.userType = [
          "系统管理员",
          "房东",
          "工程人员",
          "财务",
          "房客",
          "房管员"
        ][data.userType - 1];
        data.gender = ["男", "女"][data.gender - 1];
        this.img1 = data.idCardPic1;
        this.img2 = data.recognizePicUrl;
        this.detialsList.forEach(item => {
          item.value = data[item.key];
        });
      });
    }
  }
};
</script>
<style lang="scss">
.ied-bluetooth {
  .row-label {
    text-align: right;
  }

  .data-box {
    margin-bottom: 30px;
    max-width: 1200px;

    .data-table {
      color: #606266;
      border: solid 1px #e4e7ed;
      font-size: 14px;

      .row-label {
        background-color: #ebeef5;
      }
      .el-col {
        padding: 10px;
        height: 40px;
        line-height: 20px;
        border-left: solid 1px #e4e7ed;
      }

      .el-row {
        border-bottom: solid 1px #e4e7ed;
      }

      .el-col:nth-child(1),
      .el-row:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
}
</style>
