var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guard-detail" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("div", [_c("app-overview", { attrs: { list: _vm.detialsList } })], 1),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-around",
            width: "1204px"
          }
        },
        [
          _c("div", { staticStyle: { display: "inline-block" } }, [
            _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
              _vm._v("证件内头像")
            ]),
            _c("img", {
              staticStyle: { width: "450px", height: "280px" },
              attrs: { src: _vm.img1 }
            })
          ]),
          _c(
            "div",
            { staticStyle: { display: "inline-block", width: "450px" } },
            [
              _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                _vm._v("现场图片")
              ]),
              _c("img", {
                staticStyle: { width: "450px", height: "280px" },
                attrs: { src: _vm.img2 }
              })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }